.sidebar a {
    color: #0066cc; /* This should match your navbar link color from the image */
    margin-right: 15px; /* Adjust the margin as needed */
    text-decoration: none; /* No underline by default */
    font-family: 'Helvetica Neue', Arial, sans-serif; /* Match the font from app.css */
  }
  
  .sidebar a:hover {
    text-decoration: underline; /* Underline on hover like in the image */
  }
  