body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
}

p {
  padding: 5px;
  margin-bottom: 1.5em;
}

h1 {
  border-bottom: 2px solid #333;
  padding: 10px;
}

.date {
  font-size: 15px;
  margin-left: 5px;
}

a {
  color: #0066cc;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

nav {
  margin-bottom: 20px;
}

nav a {
  color: #0066cc;
  margin-right: 15px;
  text-decoration: none;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

nav a:hover {
  text-decoration: underline;
}

footer {
  font-size: 0.8em;
  text-align: center;
  margin-top: 50px;
}

.content {
  margin-bottom: 20px;
}

.sidebar {
  margin-top: 30px;
}

@media (max-width: 600px) {
  body {
      padding: 10px;
      align-items: center;
  }

  p {
      margin: 0 10px; /* Increase space from left and right edge */
  }

  nav {
      display: flex;
      justify-content: space-around; /* Center and evenly space navbar items */
      flex-wrap: wrap; /* Ensure navbar items stay in one line */
      padding: 0 10px; /* Add padding to ensure margins are equal on both sides */
  }

  nav a {
      flex-grow: 1; /* Ensure links fill the space and are evenly distributed */
      text-align: center; /* Center align link text */
      padding: 10px 0; /* Add padding for touch accessibility */
  }
  
  h2 {
    padding: 10px;    
  }
  
  .date {
    padding: 10px;
  }
}

.sidebar a {
  color: #0066cc;
  margin-right: 15px;
  cursor: pointer;
  text-decoration: none;
}

.sidebar a:hover {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}
